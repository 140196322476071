<template>
  <FocusTrap>
    <div class="card" id="mycard">

      <WindowTitleBar title="Jobwork Lot Receipt" @loadData="loadData" @close_window="closeThis" >{{setTitle()}}</WindowTitleBar>

      <div class="card-body">

      <div class="row">

<!--        <div class="col-md-2">-->
<!--          <div class="form-group form-group-material">-->
<!--            <label class="control-label font-weight-semibold">Process</label>-->
<!--            <select ref="el_process" class="form-control select" autofocus="" required v-if="trans.process"  v-model="trans.process_id" @change="loadOrders(trans.process_id, trans.jobworker_id)"  >-->
<!--              <option value="305" selected>Weaving</option>-->
<!--              <option value="350">Knitting</option>-->
<!--            </select>-->
<!--          </div>-->
<!--        </div>-->

        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Jobworker</label>
            <select ref="el_jobworker" class="form-control select" required="" v-if="trans"  v-model="trans.jobworker_id" @change="loadDeliveries(trans.jobworker_id)">
              <option v-for="ledger in ledgers" v-bind:value="ledger.id">
                {{ ledger.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="col-md-6">
        </div>

        <div class="col-md-2">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Receipt Date</label>
            <input ref="el_date" id="txtDate" type="date" class="form-control" v-if="trans" v-model="trans.doc_date">
          </div>
        </div>

        <div class="col-md-1">
          <div class="form-group form-group-material">
            <label class=" control-label font-weight-semibold">Delivery No</label>
            <select ref="el_delivery" class="form-control" v-if="trans.delivery" v-model="trans.delivery_id"  >
              <option v-for="order in deliveries" v-bind:value="order.id">
                {{order.doc_no}}
              </option>
            </select>
          </div>
        </div>

      </div>


      <div class="row">
        <!--  Detail Table -->
        <div class="table-responsive">

          <table ref="el_mytable"  id="mytable" class="table table-no-bordered">
            <thead style="background-color: lightgrey">
              <tr>
                <th style="width:50px;">S.No</th>
                <th >Quality</th>
                <th >Color</th>
                <th style="width:100px; text-align: right;">Pcs</th>
                <th style="width:150px; text-align: right;">Weight</th>
                <th style="width: 30px;">Action</th>
              </tr>
            </thead>
            <tbody >
              <tr  v-if="trans" v-for="(detail,index) in trans.list" >
              <td style="text-align: center;padding: 0px;"> {{index + 1}} </td>

              <td style="padding: 0px;">
                <select class="form-control" v-if="detail.quality"  v-model="detail.quality_id">
                  <option v-for="val in qualities" v-bind:value="val.id">
                    {{ val.name }}
                  </option>
                </select>
              </td>

              <td style="padding: 0px;">
                <select class="form-control" v-if="detail.color"  v-model="detail.color_id">
                  <option v-for="val in colors" v-bind:value="val.id">
                    {{ val.name }}
                  </option>
                </select>
              </td>

              <td style="padding: 0px;">
                <input type="number" class="form-control text-right" min="0"  step="any" placeholder="Pcs"  v-if="detail" v-model="detail.pcs" @change="sumRowTotal" />
              </td>

              <td style="padding: 0px;">
                <input type="number" class="form-control text-right" min="0"  step="any" placeholder="0.00" v-if="detail" v-model="detail.weight" @change="sumRowTotal"/>
              </td>

              <td style="padding: 0px;text-align: right">
                <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
              </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td style="padding: 0px;">
                <button id="btnAddRow" type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addRow">
                  <i class="icon-plus3"></i>
                </button>
              </td>
              <td></td>
              <td></td>
              <td class="text-right" ><span >{{ rowTotalPcs}}</span></td>
              <td class="text-right" ><span >{{ rowTotalWeight | formatWeight }}</span></td>
              <td></td>
            </tr>
            </tfoot>
          </table>
        </div>
        <!-- / Detail Table -->

      </div>


      <div class="row">
        <div class="col-md-3">
          <div class="form-group form-group-material">
            <label class="control-label font-weight-semibold">Narration</label>
            <textarea id="txtremarks" class="form-control" placeholder="Narration"  v-if="trans" v-model="trans.remarks" > </textarea>
          </div>
        </div>

        <div class="col-md-3">

          <div class="row">
            <div class="col-md-6 col-sm-6">
              <div class="form-group form-group-material">
                <label class="control-label font-weight-semibold">Jobworker Waste</label>
                <input class="form-control" placeholder="0.000" type="number"  v-if="trans" v-model="trans.jobworker_waste" />
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">

          <div class="row">
            <div class="col-md-6 col-sm-6">
              <div class="form-group form-group-material">
                <label class="control-label font-weight-semibold">Company Waste</label>
                <input class="form-control" placeholder="0.000" type="number"  v-if="trans" v-model="trans.company_waste" />
              </div>
            </div>
          </div>

        </div>

        <div class="col-md-3 text-right">


          <button id="btnSave" type="button" class="btn bg-green-800 btn-raised active" @click="savetrans">Save</button>
          <button type="button" id="btnclose" class="btn btn-default" data-dismiss="" @click="clear" >Clear</button>
        </div>
      </div>

    </div>

    </div>
  </FocusTrap>
</template>

<script>

import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
import DetailRow from '@/views/fms/jobworkdelivery/JobworkDeliveryDetailRow.vue'
import {store} from '@/store/store.js'
import WindowTitleBar from '../../../components/core/WindowTitleBar'

export default {
  name: 'JobworkLotReceiptForm',
  components: {
    WindowTitleBar,
    DetailRow,
  },
  store,
  props: {
    mytrans: {
      type: Object,
      default: () => JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"0001-01-01","delivery_id":0,"delivery":{"id":0,"doc_no":0,"doc_date":"0001-01-01"},"workorder_id":0,"workorder":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":"","process":{"id":0,"name":"","nature":0,"p_name":"","s_name":"","ledger":{"id":0,"type":0,"name":"","alias":"","group":{"id":0,"status":"Active","type":0,"name":"","alias":""}},"print_data":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"rate_type":0,"handhold_type":0,"handhold":0,"remarks":""},"process":{"id":0,"name":""},"jobworker_id":0,"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"remarks":"","pcs":0,"weight":0,"jobworker_waste":0,"company_waste":0,"list":[]}')
    }
  },
  beforeMount () {
    this.trans = this.mytrans;

    if(this.trans.id > 0){
      // this.loadOrders(this.trans.process_id, this.trans.jobworker_id, );
      this.loadDeliveries(this.trans.jobworker_id, );
    }
  },
  data () {
    return {
      readonly: false,
      orders:[],
      colors:[],
      ledgers:[],
      deliveries:[],
      qualities:[],
      processes:[],
      detailItems:[],

      items : new Map(),
      rowTotalWeight: 0.0,
      rowTotalPcs: 0.0,
      trans: JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"0001-01-01","delivery_id":0,"delivery":{"id":0,"doc_no":0,"doc_date":"0001-01-01"},"workorder_id":0,"workorder":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":"","process":{"id":0,"name":"","nature":0,"p_name":"","s_name":"","ledger":{"id":0,"type":0,"name":"","alias":"","group":{"id":0,"status":"Active","type":0,"name":"","alias":""}},"print_data":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"rate_type":0,"handhold_type":0,"handhold":0,"remarks":""},"process":{"id":0,"name":""},"jobworker_id":0,"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"remarks":"","pcs":0,"weight":0,"jobworker_waste":0,"company_waste":0,"list":[]}'),
    }
  },
  created () {
    const self = this;
    self.$data.trans.doc_date = moment().format('YYYY-MM-DD');
    // self.$data.trans.ref_date = moment().format('YYYY-MM-DD');
  },

  mounted () {
    const self = this;

    this.loadAll();

    if (self.$data.trans.doc_date === '0001-01-01') {
      self.$data.trans.doc_date = moment().format('YYYY-MM-DD');
      //self.$data.trans.ref_date = moment().format('YYYY-MM-DD');
    }
    self.loadColors();
    self.loadQualities();
    self.$refs.el_jobworker.focus();

  },
  filters:{
    formatWeight(val){
      return val.toFixed(3);
    }
  },
  methods:{
    loadAll() {
      this.loadJobworkers();
      this.loadColors();
      this.$data.trans = this.mytrans;
    },
    setTitle() {
      return this.trans.id == 0 ? "Jobwork Lot Receipt" : "Doc No: " + this.trans.doc_no + "  Jobwork Lot Receipt Updation";
    },
    closeThis(){
      this.$emit('jobwork_lot_receipt_window_closed');
      if(this.trans.id > 1){
        this.trans = JSON.parse('{"id":0,"status":"Active","type":0,"cmp_id":0,"finyear":0,"doc_no":0,"doc_date":"0001-01-01","order_no":"","process":{"id":0,"name":""},"jobworker":{"id":0,"name":""},"work_order":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":"","process":{"id":0,"name":""},"jobworker":{"id":0,"type":0,"name":""},"rate_type":0,"handhold_type":0,"handhold":0,"remarks":""},"list":[],"remarks":""}');
      }else{
        this.$router.push("/")
      }
    },
    loadData(){
      const self = this;
      self.loadJobworkers();
    },
    loadColors(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      $(self.$data.mytable).block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.colors = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/colorcharts`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)) {
            self.colors = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      });
    },
    loadQualities(){
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      $(self.$data.mytable).block({
        msg: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      self.qualities = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/qualities`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          if( _.isArray(resp.data)) {
            self.qualities = resp.data;
          }
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      }).finally(function () {
        $(self.$data.mytable).unblock();
      });
    },
    loadJobworkers() {
      const self = this;

      const requestOptions = {
        mode:'cors',
        headers: userService.authHeader()
      };

      $('#cmbjobworker').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      //fetch the Ledgers
      self.$data.ledgers = [];
      fetch(`${process.env.VUE_APP_ROOT_API}api/jobworkers`,requestOptions).then(userService.handleResponse).then(function (resp) {
        $('#cmbjobworker').unblock();
        if (resp.ok){
          if(_.isArray(resp.data)){
            self.$data.ledgers = resp.data;
          }
        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error"});
        }
      }).catch(function (err) {
        swal (  {title:"Oops" ,  text: err.toString(),  type: "error"});
      }).finally(function () {
        //$('#cmbjobworker').unblock();
      });

    },
    addRow(){
      const self = this;
      try {
        var detail = {
          "quality_id":0,
          "color_id":0,
          "quality": { "id": 0, "name": "" },
          "color": { "id": 0, "name": "", "weight": 0 },
          "pcs": 0,
          "weight": 0
        };
        self.$data.trans.list.push(detail);

        setTimeout(function () {
          $("tbody>tr").last().find("td:eq(1) select").focus();
        }, 99);

      }catch (e) {
        alert(e);
      }
    },
    rowValueEqualizer(rowIndex, colIndex) {
      const self = this;

      let detail = self.$data.trans.list[rowIndex];
      switch (colIndex) {
        case 1:
          detail.item = self.$data.items.get(detail.item.id);
          break;
        case 3:
          self.$data.detail.taxable_amt = detail.qty * detail.rate;
          self.$data.detail.tax_amt = (detail.taxable_amt * (detail.item.group.gstrate/100)).toFixed(2);
          self.$data.detail.net_amt = (detail.taxable_amt + parseFloat(detail.tax_amt));
          self.$data.detail.tax_amt = parseFloat(detail.tax_amt).toFixed(2);
          self.$data.detail.tax_amt = detail.tax_amt.toFixed(2);
          break;
        case 4:
          detail.taxable_amt = detail.qty * detail.rate;
          detail.tax_amt = (detail.taxable_amt * (detail.item.group.gstrate/100)).toFixed(2);
          detail.net_amt = (detail.taxable_amt + parseFloat(detail.tax_amt));
          detail.tax_amt = detail.tax_amt.toFixed(2);
          break;
        case 5:
          detail.taxable_amt = parseFloat(detail.taxable_amt);
          detail.rate = (detail.taxable_amt / detail.qty).toFixed(2);
          detail.tax_amt = (detail.taxable_amt * (detail.item.group.gstrate/100)).toFixed(2);
          detail.net_amt = (detail.taxable_amt + parseFloat(detail.tax_amt));
          break;
      }
      self.$data.rowWasteTotal = 0.0;
      self.$data.trans.list.forEach(function (detail) {
        self.$data.rowWasteTotal += parseFloat(detail.net_amt);
      });
    },
    loadItems(id){
      this.$data.trans.list=[];

      const self = this;

      const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: userService.authHeader()
      }
      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      // self.$data.voucher = JSON.parse('{"id":0,"status":"Active","type":0,"version":0,"finyear":0,"process":{"id":0,"name":"","nature":0},"jobworker":{"id":0,"type":0,"name":""},"process_id":0,"jobworker_id":0,"workorder_no":0,"doc_date":"0001-01-01","order_no":"","due_date":"0001-01-01","remarks":"","rate_type":0,"handhold_type":0,"handhold_value":0,"list":[]}');
      fetch(`${process.env.VUE_APP_ROOT_API}api/workorder/${id}`, requestOptions).then(userService.handleResponse).then(function (resp) {
        if (resp.ok) {
          if (!_.isNull(resp.data)) {

            //self.$data.voucher = resp.data;

            resp.data.list.forEach( (el) =>  {
              self.qualities.push(el.quality);
            });


          }
        } else {
          swal({ title: 'Oops!', text: resp.msg, type: 'error' })
        }
      }).catch(function (err) {
        swal({ title: 'Oh noes!', text: err.toString(), type: 'error' })
      }).finally(() => {
        $('#mycard').unblock();
      });

    },
    find_container_byname(index, con_name){
      const self = this;

      const requestOptions = {
        method:  'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      $('#mycard').block({
        message: '<i class="icon-spinner2 spinner"></i>',
        overlayCSS: {
          backgroundColor: '#fff',
          opacity: 0.8,
          cursor: 'wait',
          'box-shadow': '0 0 0 1px #ddd'
        },
        css: {
          border: 0,
          padding: 0,
          backgroundColor: 'none'
        }
      });

      fetch(`${process.env.VUE_APP_ROOT_API}api/container/name/${con_name}`,requestOptions).then(userService.handleResponse).then(function (resp) {

        if (resp.ok){

          self.$data.voucher.list[index].container = resp.data;
          self.$data.voucher.list[index].desc = resp.data.item.name;
          self.$data.voucher.list[index].yarn_weight = resp.data.yarn_weight;


        }else{
          swal (  {title:"Oops" ,  text: resp.msg,  type: "error", timer:3000});
        }
        self.sumRowAmountTotal();

      }).catch(function (err) {
        swal (  {title:"Oops" ,  text: err.toString(),  type: "error", timer:3000});
      }).finally(function (){
        $('#mycard').unblock();
      });
    },
    loadDeliveries(jobworkerid){

      const self = this;
      const requestOptions = {
        mode:'cors',
        headers: userService.authHeader()
      };

      self.$data.deliveries = [];
      if(jobworkerid > 0) {

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        // alert(`${process.env.VUE_APP_ROOT_API}api/workorders/${processid}/${jobworkerid}`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/jobwork/deliveries/nonreceived?jobworker_id=${jobworkerid}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#mycard').unblock();
          if (resp.ok) {

            if (Array.isArray(resp.data)) {
              self.$data.deliveries = resp.data;
            }

          } else {
            swal({ title: "Oops", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          $('#mycard').unblock();
          swal({ title: "Oh noes", text: err.toString(), type: "error" });
        });
      }

    },
    loadOrders(processid, jobworkerid){

      const self = this;
      const requestOptions = {
        mode:'cors',
        headers: userService.authHeader()
      };

      self.$data.orders = [];
      if(processid > 0 && jobworkerid > 0) {

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        // alert(`${process.env.VUE_APP_ROOT_API}api/workorders/${processid}/${jobworkerid}`);
        fetch(`${process.env.VUE_APP_ROOT_API}api/workorders/${processid}/${jobworkerid}`, requestOptions).then(userService.handleResponse).then(function (resp) {
          $('#mycard').unblock();
          if (resp.ok) {

            if (Array.isArray(resp.data)) {
              self.$data.orders = resp.data;
            }

          } else {
            swal({ title: "Oops", text: resp.msg, type: "error" });
          }
        }).catch(function (err) {
          $('#mycard').unblock();
          swal({ title: "Oh noes", text: err.toString(), type: "error" });
        });
      }

    },
    sumRowTotal() {
      const self = this;

      self.$data.rowTotalPcs = 0;
      self.$data.rowTotalWeight = 0;

      self.$data.trans.list.forEach(function (detail) {
        self.$data.rowTotalPcs += parseInt(detail.pcs);
        self.$data.rowTotalWeight += parseFloat(detail.weight);
      });
    },
    clear(){
      const self = this;
      self.$data.trans = JSON.parse('{"id":0,"status":"Active","finyear":0,"doc_no":0,"doc_date":"0001-01-01","delivery_id":0,"delivery":{"id":0,"doc_no":0,"doc_date":"0001-01-01"},"workorder_id":0,"workorder":{"id":0,"status":"Active","type":0,"finyear":0,"doc_no":0,"order_no":"","process":{"id":0,"name":"","nature":0,"p_name":"","s_name":"","ledger":{"id":0,"type":0,"name":"","alias":"","group":{"id":0,"status":"Active","type":0,"name":"","alias":""}},"print_data":""},"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"rate_type":0,"handhold_type":0,"handhold":0,"remarks":""},"process":{"id":0,"name":""},"jobworker_id":0,"jobworker":{"id":0,"type":0,"ldgp":0,"name":"","print_name":"","street":"","city":"","pin":"","state_cd":0,"gstin":"","contact_person":"","telephone":"","email":"","opening_balance":0},"remarks":"","pcs":0,"weight":0,"jobworker_waste":0,"company_waste":0,"list":[]}');
      self.$data.trans.doc_date = moment().format('YYYY-MM-DD');
      self.$data.trans.ref_date = moment().format('YYYY-MM-DD');
      self.$data.trans.list = [];
      self.$data.rowTotalPcs = 0;
      self.$data.rowTotalWeight = 0;
      self.$data.trans.doc_date = moment().format('YYYY-MM-DD');
      self.$data.trans.ref_date = moment().format('YYYY-MM-DD');
      self.$refs.el_jobworker.focus();
    },
    removeRow(idx){
      if (idx > -1) {
        this.$data.trans.list.splice(idx, 1);
      }
    },
    savetrans(){

      try {

        const self = this;

        if (self.$data.trans.jobworker_id <= 0) {
          alert('Invalid Jobworker');
          self.$refs.el_jobworker.focus();
          return;
        } else if (!moment(self.$data.trans.doc_date).isValid()) {
          alert('Invalid Date');
          self.$refs.el_date.focus();
          return;
        } else if (self.$data.trans.delivery_id <= 0) {
          alert('Invalid Delivery Note');
          self.$refs.el_delivery.focus();
          return;
        } else if (self.$data.trans.list.length <= 0) {
          alert('Invalid Fabric Detail');
          self.$refs.el_jobworker.focus();
          return;
        } else {
          let result = true;
          self.$data.trans.list.forEach((detail) => {

            if (detail.quality_id <= 0) {
              alert('Invalid Quality');
              self.$refs.el_mytable.focus();
              result = false;
              return;
            } else if (detail.color_id <= 0) {
              alert('Invalid Color');
              self.$refs.el_mytable.focus();
              result = false;
              return;
            } else if (parseInt(detail.pcs) <= 0) {
              alert('Invalid rolls');
              self.$refs.el_mytable.focus();
              result = false;
              return;
            } else if (parseFloat(detail.weight) <= 0) {
              alert('Invalid Weight');
              self.$refs.el_mytable.focus();
              result = false;
              return;
            }
          });

          if (!result) {
            return;
          }

        }

        let mytrans = JSON.parse(JSON.stringify(self.$data.trans));
        mytrans.finyear = store.state.user.finyear;
        mytrans.status = "Active";
        mytrans.cmp_id = self.$store.state.user.company.id;
        mytrans.emp_id = self.$store.state.user.id;
        mytrans.doc_date = moment(self.$data.trans.doc_date).format('YYYY-MM-DD');

        // mytrans.process_id = parseInt(mytrans.process_id);
        mytrans.jobworker_id = parseInt(mytrans.jobworker_id);
        // mytrans.workorder_id = parseInt(mytrans.workorder_id);
        mytrans.delivery_id = parseInt(mytrans.delivery_id);

        mytrans.jobworker_waste = parseFloat(mytrans.jobworker_waste);
        mytrans.company_waste = parseFloat(mytrans.company_waste);

        mytrans.pcs = 0;
        mytrans.weight = 0;

        mytrans.list.forEach((detail) => {
          detail.quality_id = parseInt(detail.quality_id);
          detail.color_id = parseInt(detail.color_id);
          detail.pcs = parseInt(detail.pcs);
          detail.weight = parseFloat(detail.weight);

          mytrans.pcs += detail.pcs;
          mytrans.weight += detail.weight;
        });

        const requestOptions = {
          method: (mytrans.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(mytrans)
        };

        // console.log(JSON.stringify(mytrans));

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });
        // alert(`${process.env.VUE_APP_ROOT_API}workorder/lotreceipt`);

        fetch(`${process.env.VUE_APP_ROOT_API}api/jobwork/lotreceipt`, requestOptions).then(userService.handleResponse).then(function (resp) {

          if (resp.ok) {
            self.$refs.el_jobworker.focus();
            swal({ title: 'Success', type: 'success', text: resp.msg, timer: 3000 });
            self.clear();
            self.$emit('jobwork_lotreceipt_saved');
          } else {
            swal({ title: "Oops", text: resp.msg, type: "error" });
          }

        }).catch(function (err) {
          swal({ title: "Oops", text: err.toString(), type: "error" });
        }).finally(function () {
          $('#mycard').unblock();
        });

      }catch (e) {
        alert(e);
      }
    },
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
